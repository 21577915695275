import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"

const FramesPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      Insurance: file(name: { eq: "Insurance" }) {
            publicURL
            name
      }
      SpanishInsurance: file(name: { eq: "es_insurance" }) {
            publicURL
            name
      }      
      Welcome: file(name: { eq: "Welcome to Our Office" }) {
            publicURL
            name
      }
      WelcomeBack: file(name: { eq: "Welcome Back" }) {
        publicURL
        name
  }      
      Dependentconsentform: file(name: { eq: "dependentconsentform" }) {
            publicURL
            name
      }
    }
  `)
  return (
    <Layout >
      <SEO title={'Download forms'} />
      <div className="px-4 mx-auto max-w-3xl text-navy-500 text-left lg:pt-8">
        <h1 className="text-center uppercase text-xl lg:my-2 font-bold my-4 lg:mb-12">Download Forms</h1>
        <p className=" mb-4 lg:mb-12" >
          <span className="font-bold mr-2">Frames - This is a medical optometry practice.</span>
          Please take a moment to look over our office forms. Filling them out prior to your appointment can expedite your time in with our doctors. Please contact us if you have absolutely any questions or need help.
        </p>
       <a className="flex mb-3 items-center block" href={data.Welcome.publicURL}  download >
          <svg className="fill-current w-6 h-6" viewBox="0 0 24 24">
            <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
          </svg>
         <span>Welcome to Our Office Form</span>
        </a>
        <a className="flex mb-3 items-center block" href={data.WelcomeBack.publicURL}  download >
          <svg className="fill-current w-6 h-6" viewBox="0 0 24 24">
            <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
          </svg>
         <span>Welcome back (for returning patients)</span>
        </a>        
	  {/* 
       <a className="flex mb-3 items-center block" href={data.Insurance.publicURL}  download >
          <svg className="fill-current w-6 h-6" viewBox="0 0 24 24">
            <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
          </svg>
         <span>U & M Family Eyecare Insurance Form</span>
        </a>
	    */}
	  <a className="flex mb-3 items-center block" href={data.Dependentconsentform.publicURL}  download >
          <svg className="fill-current w-6 h-6" viewBox="0 0 24 24">
            <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
          </svg>
         <span>Dependent Consent Form</span>
        </a>
       <a className="flex mb-3 items-center block" href={data.SpanishInsurance.publicURL}  download >
          <svg className="fill-current w-6 h-6" viewBox="0 0 24 24">
            <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
          </svg>
         <span>Patient Form (Spanish)</span>
        </a>        
      </div>
    </Layout>
  )
}

export default FramesPage
